import "./App.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AnswersPage from "./components/AnsawersPage";
import DescriptionAnswer from "./components/DescriptionAnswer";

function App() {
  const counter = useSelector((state) => state.counter);
  const [countQuestion, setCountQuestion] = useState(0);
  const [isQestion, setIsQuestion] = useState("");
  const [isAnswer, setIsAnwser] = useState(false);
  const [getAnswer, setGetAnwser] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);

  const [randomQuestions, setRandomQuestions] = useState([]);

// Функція для отримання 10 випадкових питань
const getRandomQuestions = (counter, num) => {
    const shuffled = [...counter].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };
  console.log('getRandomQuestions', getRandomQuestions(counter, 10))

  useEffect(() => {
    if (counter && counter.length > 0) {
      const selectedQuestions = getRandomQuestions(counter, 10);
      setRandomQuestions(selectedQuestions);
      setIsQuestion(selectedQuestions[0].question);
    }
  }, [counter]);

  useEffect(() => {
    if (randomQuestions.length > 0 && countQuestion < randomQuestions.length) {
      setIsQuestion(randomQuestions[countQuestion].question);
    }
  }, [randomQuestions, countQuestion]);

  const handleNextQuestionClick = () => {
    setGetAnwser("");
    if (countQuestion < randomQuestions.length - 1) {
      setCountQuestion((prevCount) => prevCount + 1);
      setShowAnswer(false);
    } else {
      setIsAnwser(true);
    }
  }

  const handleSubmitClick = () => {
    setRandomQuestions((prev) =>
      prev.map((question, index) =>
        index === countQuestion
          ? { ...question, answerOfUser: getAnswer }
          : question
      )
    );
    setShowAnswer(true);
  }

  return (
    <div className="App">
      {isAnswer ? (
        <AnswersPage randomQuestions={randomQuestions}  />
      ) : (
        <div className="main">
          <div className="container"> 
            <header className="header">
              <h2>{countQuestion+1} {isQestion}</h2>
            </header>
            <div className="general">
              {showAnswer ? (
                <DescriptionAnswer count={countQuestion} randomQuestions={randomQuestions} />
              ) : (
                <div className="question">
                  <textarea
                    type="text"
                    className="input-answer"
                    onChange={(e) => setGetAnwser(e.target.value)}
                    value={getAnswer}
                  />
                </div>
              )}
            </div>
          </div>
          {
            showAnswer ? (
              <button onClick={handleNextQuestionClick}>
                НАСТУПНЕ ЗАПИТАННЯ
              </button>
            ) : (
              <button onClick={handleSubmitClick}>
                ВІДПРАВИТИ
              </button>
            )
          }
        </div>
      )}
    </div>
  );
}

export default App;
